'use client';
import { useState } from 'react';
import { SearchIcon } from '@/app/components/icons';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { useGetChannelPref } from '@/providers/ChannelProvider';
import { generateChannelPath } from '@/utils';

type Props = {
  onCloseMenu?: () => void;
  parentClassName?: string;
  onInputHover?: () => void;
};

export default function SearchInput({
  onCloseMenu,
  parentClassName,
  onInputHover,
}: Props) {
  const t = useTranslations('');
  const router = useRouter();
  const [searchText, setSearchText] = useState('');
  const channelPref = useGetChannelPref();
  const onNavigateSearchPage = () => {
    router.push(`/${generateChannelPath(channelPref)}/search?q=${searchText}`);
    onCloseMenu?.();
  };

  const onKeyDown = (key: string) => {
    if (key === 'Enter') {
      onNavigateSearchPage();
    }
  };

  return (
    <div
      className={cn(
        'px-4 py-6 flex flex-row w-full bg-grey-2',
        parentClassName,
      )}
    >
      <input
        onKeyDown={e => onKeyDown(e.key)}
        onChange={e => setSearchText(e.target.value)}
        onMouseEnter={onInputHover}
        value={searchText}
        className="h-12 px-4 w-full bg-grey-1 border border-grey-5 text-base font-notosans text-grey-8 leading-normal outline-none"
        placeholder={t('search-placeholder')}
      />
      <div
        onClick={() => onNavigateSearchPage()}
        className="bg-primary-2 hover:bg-primary-1 active:bg-primary-2 p-2 flex items-center justify-center min-w-12 h-12 cursor-pointer"
      >
        <SearchIcon color="#FFFFFF" />
      </div>
    </div>
  );
}
