import { Content, MenuItem, Topic } from '@/lib/model';
import { LinkWithChannelLink } from '../../link/LinkWithChannel';

export const MenuItemLink: React.FC<{
  menu: MenuItem;
  children?: any;
}> = ({ children, menu }) => {
  if (menu.linkType === 'custom') {
    return (
      <a
        href={menu.url as string}
        key={menu.id}
        rel="noreferrer"
        target="_blank"
      >
        {children}
      </a>
    );
  }

  if (menu.linkType === 'live_coverage' && menu.liveCoverage?.id) {
    return (
      <LinkWithChannelLink
        href={`/live/${(menu.liveCoverage as unknown as Content)?.id}`}
        key={`${menu.id}`}
      >
        {children}
      </LinkWithChannelLink>
    );
  }
  if (menu.linkType === 'content' && menu.content) {
    return (
      <LinkWithChannelLink
        href={`/${(menu.content as Content)?.type}/${(menu.content as Content)?.slug}`}
        key={`${menu.id}`}
      >
        {children}
      </LinkWithChannelLink>
    );
  }
  if (menu.linkType === 'topic' && (menu?.topic as Topic)?.page?.slug) {
    return (
      <LinkWithChannelLink
        href={`/${(menu?.topic as Topic)?.page?.slug}`}
        key={`${menu.id}`}
      >
        {children}
      </LinkWithChannelLink>
    );
  }
  if (menu.linkType === 'page' && menu.page?.slug) {
    return (
      <LinkWithChannelLink href={`/${menu.page?.slug}`} key={`${menu.id}`}>
        {children}
      </LinkWithChannelLink>
    );
  }
  if (menu.slug)
    return (
      <LinkWithChannelLink href={`/${menu.slug}`} key={`${menu.id}`}>
        {children}
      </LinkWithChannelLink>
    );
  return <></>;
};
