import { useEffect, useState, RefObject } from 'react';

interface UseIntersectionObserverProps {
  target: RefObject<HTMLElement> | string;
  root?: Element | null;
  rootMargin?: string;
  threshold?: number;
  initialState?: boolean;
}

const useIntersectionObserver = ({
  target,
  root = null,
  rootMargin = '0px',
  threshold = 0.1,
  initialState = false,
}: UseIntersectionObserverProps): boolean => {
  const [isIntersecting, setIsIntersecting] = useState(initialState);

  useEffect(() => {
    let element: HTMLElement | null = null;

    if (typeof target === 'string') {
      element = document.getElementById(target);
    } else if (target && 'current' in target) {
      element = target.current;
    }

    if (!element) return;

    const observer = new IntersectionObserver(
      ([entry]) => setIsIntersecting(entry.isIntersecting),
      { root, rootMargin, threshold },
    );
    observer.observe(element);

    return () => {
      observer.unobserve(element as HTMLElement);
    };
  }, [target, root, rootMargin, threshold]);

  return isIntersecting;
};

export default useIntersectionObserver;
