'use client';
import { useGetChannelPref } from '@/providers/ChannelProvider';

const turkieText = ['turkiye', 'türkiye', 'TÜRKIYE'].map(c => c.toLowerCase());

export function LocaleUppercase({ children }: { children?: string | null }) {
  const { languageCode } = useGetChannelPref();

  if (!children || typeof children !== 'string') return '';
  if (turkieText.includes(children.toLowerCase())) return 'TÜRKİYE';
  if (!languageCode) return children.toUpperCase();
  return children.toLocaleUpperCase(languageCode);
}
