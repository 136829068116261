'use client';

import { GroupedChannels, useChannelList } from '@/providers/ChannelProvider';
import { forwardRef, useEffect, useRef, useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { useSetChannel } from '@/hooks/useSetChannel';
import { LogoVariant } from '@/utils/setChannelColor';
import logoStyles from '../channel-logo/channelStyle.module.scss';

import { Channel, SelectedMedia } from '@/lib/model';
import { MenuName } from './SideMenuWrapper';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { TRT_CHANNEL_LOGO_MULTICOLOR_CLASSNAME } from '../header/constant';
import { ChannelLogoImage } from '../channel-logo/client';

function nope() {}

type Props = {
  onCloseMenu?: () => void;
  handleMouseEnter?: (name: MenuName | undefined) => () => void;
  channelsWrapperClassName?: string;
  mainWrapperClassName?: string;
};

export const SubMenuChannelList = forwardRef<HTMLDivElement, Props>(
  (
    {
      onCloseMenu,
      handleMouseEnter,
      channelsWrapperClassName,
      mainWrapperClassName,
    },
    ref,
  ) => {
    const t = useTranslations('');
    const { groupedChannels } = useChannelList();
    const setChannel = useSetChannel();
    const [search, setSearch] = useState('');
    const [filteredChannels, setFilteredChannels] = useState<GroupedChannels>(
      [],
    );

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const onClickChannel = (channel: Channel) => {
      setChannel(channel);
      onCloseMenu?.();
    };

    const onSearchChange = (value: string) => {
      setSearch(value);
      if (!value) {
        setFilteredChannels([]);
        return;
      }

      setFilteredChannels(
        groupedChannels.filter(channel => {
          if (channel.name.toLowerCase().includes(value.toLowerCase())) {
            return true;
          }
          return false;
        }),
      );
    };

    const channels = search ? filteredChannels : groupedChannels;
    return (
      <div
        ref={ref}
        dir="inherit"
        onMouseLeave={handleMouseEnter?.(undefined)}
        onMouseEnter={handleMouseEnter?.(MenuName.Channel)}
        className={cn(
          'absolute hidden sm:block start-0 bg-grey-3 top-0 z-[30] h-full w-full p-10 pb-15 overflow-scroll',
          mainWrapperClassName,
        )}
      >
        <div className="mb-7 w-full">
          <input
            ref={inputRef}
            type="text"
            onChange={e => onSearchChange(e.target.value)}
            value={search}
            autoFocus
            placeholder={t('channel-search-placeholder')}
            className="bg-transparent w-full border-b border-grey-9 outline-none text-black"
          />
        </div>
        {channels?.length > 0 && (
          <div
            className={cn(
              'rounded-custom_4 gap-2.5 grid xl:grid-cols-5 lg:grid-cols-3 grid-cols-2 pb-10',
              channelsWrapperClassName,
            )}
          >
            {channels?.map(channel => {
              const hasChildren = Boolean(channel.children?.length);
              return (
                <Tooltip.Provider key={channel.languageCode} delayDuration={0}>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <button
                        onClick={
                          hasChildren ? nope : () => onClickChannel(channel)
                        }
                        className={cn(
                          'bg-white group/image w-full col-span-1 rounded-custom h-[80px] px-6 flex items-center justify-center text-xl font-sf uppercase font-bold text-[#4F5657]',
                          TRT_CHANNEL_LOGO_MULTICOLOR_CLASSNAME,
                          logoStyles.dynamicChannelLogoFilterEffect,
                        )}
                      >
                        {/* Channel logo, we will show default logo on the initial, and on hover we will show the white one */}
                        <ChannelLogoImage
                          languageName={channel.name}
                          languageCode={channel.languageCode}
                          src={
                            (
                              channel.websiteLogo as unknown as SelectedMedia['media']
                            )?.url
                          }
                          className="px-2"
                        />

                        {/* It is for testing, we will show here if hovered channel has multiple languages */}
                      </button>
                    </Tooltip.Trigger>

                    {channel.children?.length ? (
                      <Tooltip.Content
                        sideOffset={5}
                        style={{ zIndex: 99, marginTop: -90 }}
                        side="bottom"
                      >
                        <div className="flex flex-col bg-grey-13 rounded-custom w-[320px] h-[112px] px-4 mb-2 justify-center items-center text-xl font-sf uppercase font-bold text-white">
                          <ChannelLogoImage
                            languageName={channel.name}
                            languageCode={channel.languageCode}
                            logoVariant={LogoVariant.Light}
                            className="mb-2 px-2"
                            src={
                              (
                                channel.websiteLogo as unknown as SelectedMedia['media']
                              )?.url
                            }
                          />
                          <div className="h-[1px] w-full bg-grey-8 mb-2" />

                          <div className="flex flex-row">
                            {channel.children.map((mChannel, index) => (
                              <button
                                onClick={() => onClickChannel(mChannel)}
                                key={`${channel.languageCode}-${index}`}
                                className="px-2 py-1 font-sf text-sm leading-4 font-medium uppercase text-grey-1 hover:text-grey-8"
                              >
                                {mChannel.name}
                              </button>
                            ))}
                          </div>
                        </div>
                      </Tooltip.Content>
                    ) : null}
                  </Tooltip.Root>
                </Tooltip.Provider>
              );
            })}
          </div>
        )}
        {channels?.length === 0 && (
          <div className="w-full text-grey-12 font-notosans text-xl">
            No results found. Please try searching with a different channel
            name.
          </div>
        )}
      </div>
    );
  },
);
