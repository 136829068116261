import { MenuItem } from '@/lib/model';
import { MenuItemLink } from '../../header/menu/MenuItemLink';
import { LocaleUppercase } from '../../text/LocaleUppercase';

type Props = {
  menuItems?: MenuItem[];
  onCloseMenu: () => void;
};

export function MobileSubMenuList({ menuItems, onCloseMenu }: Props) {
  if (!menuItems || menuItems?.length === 0) return null;
  return (
    <div className="px-6 py-3 flex flex-col bg-grey-3 rounded-custom_4">
      {menuItems?.map(menu => (
        <div
          onClick={onCloseMenu}
          key={menu.id}
          className="flex px-4 py-3 text-grey-8 font-notosans text-base leading-normal"
        >
          <MenuItemLink menu={menu}>
            <LocaleUppercase>{menu.name}</LocaleUppercase>
          </MenuItemLink>
        </div>
      ))}
    </div>
  );
}
