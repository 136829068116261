import { DeviceType } from '@/types/devices';
import { useEffect, useState } from 'react';

const DESKTOP_SIZE = 1240;
const MOBILE_SIZE = 670;

export function useDeviceType(agentDeviceType: DeviceType = 'desktop') {
  const [deviceType, setDeviceType] = useState<'mobile' | 'tablet' | 'desktop'>(
    agentDeviceType,
  );

  useEffect(() => {
    const handleResize = () => {
      setDeviceType(
        window.innerWidth < MOBILE_SIZE
          ? 'mobile'
          : window.innerWidth < DESKTOP_SIZE
            ? 'tablet'
            : 'desktop',
      );
    };

    handleResize(); // Check on initial load
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return {
    deviceType,
    isMobile: deviceType === 'mobile',
    isDesktop: deviceType === 'desktop',
    isTable: deviceType === 'tablet',
  };
}
