'use client';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { TRT_STICKY_HEADER_WRAPPER } from '../../constant';

const MenuStateContext = createContext(
  {} as {
    isMenuOpen: boolean;
    isLanguageSelectionOpen: boolean;
    setIsLanguageSelectionOpen: Dispatch<SetStateAction<boolean>>;
    isVisible: boolean;
    onMenuClick: () => void;
  },
);

export const MenuStateProvider = ({ children }: PropsWithChildren) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguageSelectionOpen, setIsLanguageSelectionOpen] = useState(false);
  const isVisible = useIntersectionObserver({
    target: TRT_STICKY_HEADER_WRAPPER,
    threshold: 0,
  });
  const onMenuClick = () => {
    setIsMenuOpen(open => !open);
  };
  return (
    <MenuStateContext.Provider
      value={{
        isMenuOpen,
        isLanguageSelectionOpen,
        setIsLanguageSelectionOpen,
        isVisible,
        onMenuClick,
      }}
    >
      {children}
    </MenuStateContext.Provider>
  );
};

export function useHeaderSideMenuStete() {
  const states = useContext(MenuStateContext);
  return states;
}
