import { MenuItem } from '@/lib/model';
import React from 'react';
import { MenuItemLink } from '../header/menu/MenuItemLink';

type Props = {
  menuItems: (string | MenuItem)[] | null;
  onCloseMenu: () => void;
};

export function SubMenuList({ menuItems, onCloseMenu }: Props) {
  const subMenuItems = menuItems?.map(item => item as MenuItem);

  return (
    <div
      dir="inherit"
      className="ps-4 flex transition-all opacity-0 invisible ease-out duration-160 delay-480 group-hover:opacity-100 group-hover:visible top-[105] absolute start-full "
    >
      <div className="flex gap-4 w-[332px] p-4 flex-col bg-grey-3">
        {subMenuItems?.map(item => (
          <MenuItemLink key={item.id} menu={item}>
            <div
              className="bg-grey-1 hover:bg-grey-2 hover:text-grey-13 text-grey-8 px-4 py-3"
              onClick={onCloseMenu}
            >
              {item.name}
            </div>
          </MenuItemLink>
        ))}
      </div>
    </div>
  );
}
