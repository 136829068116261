'use client';
import { useState } from 'react';
import { ChevronBottomIcon, ChevronTopIcon } from '@/app/components/icons';
import {
  MobileMenuChannelList,
  MobileSubMenuList,
} from '@/app/components/side-menu/mobile';
import { MenuItem } from '@/lib/model';

type Props = {
  title: string; //TODO for UI design, it will be changed
  listType: 'list' | 'channel'; //TODO Added for statically show different lists, when cms will ready for this we will remove it
  onCloseMenu: () => void;
  menuItems?: (string | MenuItem)[] | null;
};

export function MobileSideMenuItem({
  title,
  listType,
  onCloseMenu,
  menuItems,
}: Props) {
  const subMenuItems = menuItems?.map(item => item as MenuItem);
  const [showSubmenu, setShowSubmenu] = useState(false);

  const onClickMenuItem = () => {
    setShowSubmenu(show => !show);
  };

  return (
    <div className="flex flex-col w-full">
      <div
        className="flex flex-row items-center justify-between w-full cursor-pointer py-4 px-4"
        onClick={onClickMenuItem}
      >
        <div className="text-grey-8 font-notosans text-base leading-normal">
          {title}
        </div>
        {showSubmenu ? (
          <ChevronTopIcon color="#303232" />
        ) : (
          <ChevronBottomIcon color="#303232" />
        )}
      </div>

      {showSubmenu && (
        <>
          {listType === 'list' && (
            <MobileSubMenuList
              menuItems={subMenuItems}
              onCloseMenu={onCloseMenu}
            />
          )}
          {listType === 'channel' && (
            <MobileMenuChannelList onCloseMenu={onCloseMenu} />
          )}
        </>
      )}
    </div>
  );
}
