'use client';

import { isRTL } from '@/i18n/utils';
import { cn } from '@/lib/utils';
// import { LogoVariant, setChannelColor } from '@/utils/setChannelColor';
import { useLocale } from 'next-intl';
import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TRT_CHANNEL_LOGO_CLASSNAME } from '../../header/constant';
import { LogoVariant } from '@/utils/setChannelColor';
import styles from '../channelStyle.module.scss';
import { useHeaderSideMenuStete } from '../../header/main-header-sidebar-wrapper/components/MenuStateProvider';
import { useGetChannelPref } from '@/providers/ChannelProvider';

const SHORT_CHANNEL_NAME_LENGTH = 7;
const CACHE_PATH = '/channel/svg/icon';
const CACHE_TIMEOUT = 60 * 60 * 24;
interface Props extends HTMLAttributes<HTMLDivElement> {
  svgClassName?: string;
  src?: string | null;
  // languageName: string;
  // languageCode: string;
  initialColor?: string;
  logoVariant?: LogoVariant;
  scale?: number;
  removeEmptySpace?: boolean;
  autoSyncSizes?: boolean;
  noAnimation?: boolean;
  showFullLogo?: boolean;
  withOutTRT?: boolean;
}

// Utility function to fetch and cache the SVG
async function fetchAndCacheSVG(url: string, fallbackURL: string) {
  const cache = await caches.open(CACHE_PATH);
  const cachedResponse = await cache.match(url);
  const currentTime = new Date().getTime();

  if (cachedResponse) {
    const cachedData = await cachedResponse.json();
    const cacheTimestamp = cachedData.timestamp;

    // Check if the cached SVG has expired
    const timeDifference = (currentTime - cacheTimestamp) / 1000; // in seconds
    if (timeDifference < CACHE_TIMEOUT) {
      return cachedData.content;
    } else {
      await cache.delete(url);
    }
  }
  let response: Response;
  try {
    response = await fetch(url, { cache: 'force-cache' });
  } catch {
    response = await fetch(fallbackURL, { cache: 'force-cache' });
  }
  if (!response.status.toString().startsWith('2')) {
    response = await fetch(fallbackURL);
  }

  const svgContent = await response.text();
  const cachedData = {
    content: svgContent, // The actual SVG text content
    timestamp: currentTime, // The current timestamp
  };
  await cache.put(
    url,
    new Response(JSON.stringify(cachedData), {
      headers: { 'Content-Type': 'application/json' },
    }),
  );

  return svgContent;
}
export const ChannelLogoClientside = ({
  className,
  logoVariant = LogoVariant.Dark,
  svgClassName,
  removeEmptySpace,
  autoSyncSizes,
  noAnimation,
  showFullLogo,
  style,
  withOutTRT,
  ...rest
}: Props) => {
  const channelPref = useGetChannelPref();
  const languageCode = channelPref.languageCode;
  const languageName = channelPref.name;

  const ref = useRef<HTMLDivElement>(null);
  const channelName = useMemo(
    () => languageName?.split(' ')?.[0],
    [languageName],
  );
  const { isVisible } = useHeaderSideMenuStete();
  const [svgElement, setSvgElement] = useState<SVGAElement | null>(null);
  const locale = useLocale();
  const isLargeChannelName = useCallback((name: string) => {
    return name.length > SHORT_CHANNEL_NAME_LENGTH;
  }, []);

  const { svgUrl, includeLightLogoStyles } = useMemo(() => {
    const includeLightLogoStyles = LogoVariant.Light;
    return {
      includeLightLogoStyles,
      svgUrl: `/svg/channels/${languageCode}.svg`,
    };
  }, [languageCode]);

  /**
   * This function display channel full name with TRT or just channel name based on
   * header position is sticky or not
   */
  const setChannelParts = useCallback(
    (svgElement: SVGAElement, isMainLogoInView: boolean, locale: string) => {
      if (!svgElement.style || withOutTRT) return;

      const selector = 'g[data-name="trt"]';
      const elTransform = isRTL(locale) ? 35 : -35;
      const gTransform = isRTL(locale) ? 75 : -75;

      if (noAnimation && showFullLogo) {
        (
          svgElement.querySelectorAll(selector) as unknown as Array<SVGAElement>
        ).forEach(el => {
          el.style.opacity = '1';
        });
      } else if (noAnimation && !showFullLogo) {
        svgElement.style.translate = `${elTransform}px`;
        svgElement.style.height = `100%`;

        (
          svgElement.querySelectorAll(selector) as unknown as Array<SVGAElement>
        ).forEach(el => {
          el.style.transitionProperty = 'transform translate scale opacity';
          el.style.transformOrigin = 'center';
          el.style.opacity = '0';
        });
      }

      if (noAnimation) return;
      svgElement.style.transitionProperty = 'transform opacity';
      svgElement.style.transitionDuration = '0.3s';
      /**
       * All Channel logo SVG should have same structure so we can grab the TRT
       * part of logo and animate it, right all TRT part is within <g> tag
       * For the World logo it has different structure and TRT part is inside rect
       * TODO: Make this logic robust
       */

      // const elScale = scale || 1;
      if (isMainLogoInView) {
        svgElement.style.translate = `${elTransform}px`;

        (
          svgElement.querySelectorAll(selector) as unknown as Array<SVGAElement>
        ).forEach(el => {
          el.style.transitionProperty = 'transform translate opacity';
          el.style.transformOrigin = 'center';
          el.style.transitionDuration = '0.3s';
          el.style.opacity = '0';
          el.style.translate = `${gTransform}px`;
        });
      } else {
        svgElement.style.translate = '0';
        (
          svgElement.querySelectorAll(selector) as unknown as Array<SVGAElement>
        ).forEach(el => {
          el.style.transitionProperty = 'transform translate opacity';
          el.style.transitionDuration = '0.3s';
          el.style.opacity = '1';
          el.style.translate = '0';
        });
      }
    },
    [noAnimation, showFullLogo],
  );
  useEffect(() => {
    if (!ref.current?.firstChild) {
      return () => {};
    }
    if (svgElement) {
      setSvgElement(svgElement);
      setChannelParts(svgElement, isVisible, locale);
    }
  }, [isVisible, locale, setChannelParts, svgElement]);

  useEffect(() => {
    if (!ref.current) return () => {};
    fetchAndCacheSVG(svgUrl, svgUrl)
      .then(svg => {
        if (!svg || !ref.current) return;
        if (svg.startsWith('<svg')) {
          ref.current.innerHTML = svg;
          // Optional: Change the color of the SVG
          const svgElement = ref.current.querySelector(
            'svg',
          ) as unknown as SVGAElement;
          if (svgElement) {
            if (withOutTRT) {
              const selector = 'g[data-name="trt"]';
              (
                svgElement.querySelectorAll(
                  selector,
                ) as unknown as Array<SVGAElement>
              ).forEach(el => {
                el.remove();
              });
            }
            if (removeEmptySpace) {
              const bbox = svgElement.getBBox();
              const viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(
                ' ',
              );
              svgElement.setAttribute('viewBox', viewBox);
              if (autoSyncSizes) {
                svgElement.setAttribute('width', `${bbox.width}`);
                svgElement.setAttribute('height', `${bbox.height}`);
              }
            }

            if (svgClassName) {
              svgClassName.split(' ').forEach(className => {
                svgElement.classList.add(className);
              });
            }
            if (!withOutTRT) {
              setChannelParts(svgElement, Boolean(isVisible), locale);
            }

            setSvgElement(svgElement);
          }
        } else {
          ref.current.innerHTML = channelName;
        }
      })
      .catch(() => {
        if (ref.current) ref.current.innerHTML = channelName;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      dir="inherit"
      ref={ref}
      style={style}
      className={cn(
        `w-auto text-lg flex justify-center align-center`,
        TRT_CHANNEL_LOGO_CLASSNAME,
        {
          ['md:text-sm text-lg h-auto']: isLargeChannelName(channelName),
          ['md:text-lg text-xl h-auto']: !isLargeChannelName(channelName),
          [styles.lightChannelLogoFilterEffect]:
            logoVariant === LogoVariant.Light && includeLightLogoStyles,
        },
        className,
      )}
      {...rest}
    ></div>
  );
};
