import SearchInput from '@/app/components/side-menu/SearchInput';
import { Menu, MenuItem } from '@/lib/model';
import { MobileSideMenuItem } from './MobileSideMenuItem';
import { CHANNEL_LIST_MENU_ITEM } from '../constant';
import { useTranslations } from 'next-intl';
import { MenuItemLink } from '../../header/menu/MenuItemLink';

type Props = {
  menu?: Menu;
  noChannelList?: boolean;
  onCloseMenu: () => void;
};

export function MobileSideMenuWrapper({
  menu,
  noChannelList,
  onCloseMenu,
}: Props) {
  const t = useTranslations();

  const primaryMenuItems =
    menu?.primaryMenuItems?.map(item => item as MenuItem) || [];
  const secondaryMenuItems =
    menu?.secondaryMenuItems?.map(item => item as MenuItem) || [];

  const allMenuItems = [...primaryMenuItems, ...secondaryMenuItems];

  return (
    <div className="w-full pb-6 absolute 2xl:top-header2xlH lg:top-headerLgH top-headerDefaultH h-full bg-grey-2 z-[4] left-0 min-h-screen sm:hidden flex flex-col">
      <SearchInput onCloseMenu={onCloseMenu} />

      <div className="flex flex-col bg-white h-full overflow-y-scroll scrollbar-none pb-15">
        {noChannelList ? null : (
          <MobileSideMenuItem
            onCloseMenu={onCloseMenu}
            listType={'channel'}
            title={t(CHANNEL_LIST_MENU_ITEM.name)}
          />
        )}
        {allMenuItems?.map((item, index) =>
          item.menuItems && item.menuItems?.length > 0 ? (
            <MobileSideMenuItem
              onCloseMenu={onCloseMenu}
              listType={'list'}
              key={`${item.id}-${index}`}
              menuItems={item.menuItems}
              title={item.name}
            />
          ) : (
            <MenuItemLink key={`${`${item.id}-${index}`}`} menu={item}>
              <div className="flex flex-col justify-between text-grey-8 font-notosans text-base leading-normal py-4 px-4">
                {item.name}
              </div>
            </MenuItemLink>
          ),
        )}
      </div>
    </div>
  );
}
