import {
  HTMLProps,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import SearchInput from './SearchInput';
import { Menu, MenuItem as MenuItemType } from '@/lib/model';
import {
  ChevronRightIcon,
  RectangleIcon,
  WorldIcon,
} from '@/app/components/icons';
import { CHANNEL_LIST_MENU_ITEM } from './constant';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import { SubMenuChannelList } from './SubMenuChannelList';
import { SubMenuList } from './SubMenuList';
import { MenuItemLink } from '../header/menu/MenuItemLink';
import { getValidMenuItem } from '@/common/utils';

const CLOSE_DELAY = 300;

type Props = {
  onCloseMenu: () => void;
  menu?: Menu;
  isMainFeaturesLayout?: boolean;
  isArticlePage?: boolean;
  noChannelList?: boolean;
  sideMenuClassName?: string;
};

export enum MenuName {
  Channel = 'channel',
}

interface MenuProps extends HTMLProps<HTMLDivElement>, PropsWithChildren {
  name: string;
  hasSubmenu?: boolean;
  isOpen?: boolean;
}

const MenuItem = ({
  name,
  hasSubmenu,
  isOpen,
  className,
  children,
  ...rest
}: MenuProps) => {
  //TODO : remove this after global channels coming dynamic
  const t = useTranslations();
  const isGlobalChannelsMenu = name === 'trt-global-channels';
  return (
    <div
      className={cn(
        'group hover:bg-grey-2 hover:text-grey-13 cursor-pointer relative pl-8 pr-4 py-4 text-grey-8 font-notosans text-base leading-normal flex flex-row items-center justify-between last:mb-6',
        className,
        {
          'bg-grey-3 hover:bg-grey-3 hover:scale-105 transition-all duration-300 text-grey-13':
            isGlobalChannelsMenu,
        },
      )}
      onClick={e => {
        e.preventDefault();
      }}
      {...rest}
    >
      {isGlobalChannelsMenu && <WorldIcon color="#000000" />}
      <div
        className={cn('w-full', {
          'ps-2': isGlobalChannelsMenu,
        })}
      >
        {isGlobalChannelsMenu ? t(CHANNEL_LIST_MENU_ITEM.name) : name}
      </div>
      {children || hasSubmenu ? (
        <ChevronRightIcon
          width="28"
          className={cn('origin-center hover:scale-105 rtl:rotate-180', {
            'rotate-180 rtl:rotate-0': isOpen,
          })}
        />
      ) : null}
      <div
        dir="inherit"
        className={cn(
          'pl-2 sm:group-hover:flex hidden z-20 absolute start-full',
          {
            flex: isOpen,
          },
        )}
      >
        {!isGlobalChannelsMenu && <RectangleIcon color="#F1F1F1" />}
      </div>
      {children}
    </div>
  );
};

export function SideMenuWrapper({
  onCloseMenu,
  menu,
  isMainFeaturesLayout,
  isArticlePage,
  noChannelList,
  sideMenuClassName,
}: Props) {
  const primaryMenuItems =
    menu?.primaryMenuItems?.map(item => item as MenuItemType) || [];
  const secondaryMenuItems =
    menu?.secondaryMenuItems?.map(item => item as MenuItemType) || [];

  const allMenuItems = [...primaryMenuItems, ...secondaryMenuItems];
  const [focusedMenu, focusMenu] = useState<MenuName | undefined>(undefined);
  const mouseEnterTimeout = useRef<NodeJS.Timeout>();
  const handleMouseEnter = useCallback(
    (name: MenuName | undefined, delay?: number) => () => {
      if (mouseEnterTimeout.current) {
        clearTimeout(mouseEnterTimeout.current);
      }
      if (delay) {
        mouseEnterTimeout.current = setTimeout(
          () => {
            focusMenu(name);
          },
          Math.min(delay, CLOSE_DELAY),
        );
        return;
      }
      focusMenu(name);
    },
    [],
  );
  useEffect(() => {
    return () => {
      if (mouseEnterTimeout.current) {
        clearTimeout(mouseEnterTimeout.current);
      }
    };
  }, []);
  return (
    <>
      {/* Dark background */}
      <div
        dir="inherit"
        onClick={onCloseMenu}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.16)' }}
        className={cn(
          'w-full absolute h-screen z-[20] bottom-0 start-0 end-0 sm:block hidden',
          {
            'top-headerDefaultH lg:top-headerLgH 2xl:top-header2xlH':
              !isMainFeaturesLayout && !isArticlePage,
            'sm:top-[84px] top-20': isMainFeaturesLayout,
            'xl:top-globalTopHeaderXlH lg:top-globalTopHeaderLgH top-globalTopHeaderH':
              isArticlePage,
          },
          sideMenuClassName,
        )}
      />
      <div
        className={cn(
          'sm:w-sidebarWidth w-full h-full min-h-screen absolute start-0 bg-grey-2 z-[21] sm:flex hidden flex-col',
          {
            'sm:top-headerDefaultH lg:top-headerLgH 2xl:top-header2xlH':
              !isMainFeaturesLayout && !isArticlePage,
            'sm:top-[84px] top-20': isMainFeaturesLayout,
            'xl:top-globalTopHeaderXlH lg:top-globalTopHeaderLgH top-globalTopHeaderH':
              isArticlePage,
          },
          sideMenuClassName,
        )}
      >
        <div onMouseLeave={handleMouseEnter(undefined, CLOSE_DELAY)}>
          <MenuItem
            {...CHANNEL_LIST_MENU_ITEM}
            onMouseEnter={handleMouseEnter(MenuName.Channel)}
            hasSubmenu
            isOpen={focusedMenu === MenuName.Channel}
          />
          {/* Search Input */}
          <SearchInput
            onCloseMenu={onCloseMenu}
            // parentClassName="pt-0"
            onInputHover={handleMouseEnter(undefined, CLOSE_DELAY)}
          />
        </div>
        <div
          className="flex flex-col bg-white scrollbar-none pb-15"
          onMouseLeave={() => handleMouseEnter(undefined)}
        >
          {allMenuItems?.map((item, index) =>
            item.menuItems &&
            item?.menuItems?.filter(getValidMenuItem).length &&
            item?.menuItems?.length > 0 ? (
              <MenuItem
                onMouseEnter={handleMouseEnter(undefined)}
                key={`${item.id}-${index}`}
                name={item.name}
              >
                <SubMenuList
                  onCloseMenu={handleMouseEnter(undefined)}
                  menuItems={item?.menuItems}
                />
              </MenuItem>
            ) : (
              <MenuItemLink menu={item} key={`${item.id}-${index}`}>
                <div
                  onMouseEnter={handleMouseEnter(undefined, CLOSE_DELAY)}
                  className="hover:bg-grey-2 hover:text-grey-13 pl-8 pr-4 py-4 text-grey-8 font-notosans text-base leading-normal"
                >
                  {item.name}
                </div>
              </MenuItemLink>
            ),
          )}
        </div>
      </div>
      {!noChannelList && focusedMenu === MenuName.Channel && (
        <div
          className={cn(
            'absolute top-headerDefaultH lg:top-headerLgH 2xl:top-header2xlH w-[calc(100vw-136px)] md:w-[calc(100vw-388px)] h-screen bg-grey-3 start-[136px]  md:start-sidebarWidth',
            {
              'sm:!top-[84px] !top-20': isMainFeaturesLayout,
              'xl:top-globalTopHeaderXlH lg:top-globalTopHeaderLgH top-globalTopHeaderH':
                isArticlePage,
            },
            sideMenuClassName,
          )}
        >
          <SubMenuChannelList
            handleMouseEnter={handleMouseEnter}
            onCloseMenu={onCloseMenu}
          />
        </div>
      )}
    </>
  );
}
