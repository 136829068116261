import { useState } from 'react';
import { GroupedChannels, useChannelList } from '@/providers/ChannelProvider';

import clsx from 'clsx';
import * as Tooltip from '@radix-ui/react-tooltip';
import { useSetChannel } from '@/hooks/useSetChannel';
import { LogoVariant } from '@/utils/setChannelColor';
import { Channel, SelectedMedia } from '@/lib/model';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { TRT_CHANNEL_LOGO_MULTICOLOR_CLASSNAME } from '../../header/constant';
import { ChannelLogoImage } from '../../channel-logo/client';

type Props = {
  onCloseMenu?: () => void;
  mainWrapperClassName?: string;
};

export function MobileMenuChannelList({
  onCloseMenu,
  mainWrapperClassName,
}: Props) {
  const t = useTranslations('');

  const { groupedChannels } = useChannelList();

  const [clickedChannel, setClickedChannel] = useState<Channel | undefined>();

  const setChannel = useSetChannel();
  const [search, setSearch] = useState('');
  const [filteredChannels, setFilteredChannels] = useState<GroupedChannels>([]);

  const onSearchChange = (value: string) => {
    setSearch(value);
    if (!value) {
      setFilteredChannels([]);
      return;
    }

    setFilteredChannels(
      groupedChannels.filter(channel => {
        if (channel.name.toLowerCase().includes(value.toLowerCase())) {
          return true;
        }
        return false;
      }),
    );
  };

  const onChannelSet = (channel: Channel) => {
    setChannel(channel);
    onCloseMenu?.();
  };
  const channels = search ? filteredChannels : groupedChannels;
  return (
    <div
      className={cn(
        'p-6 sm:pb-6 pb-15 rounded-custom_4 overflow-y-scroll bg-grey-3 gap-2.5 grid grid-cols-2',
        mainWrapperClassName,
      )}
    >
      <div className="mb-4 w-full col-span-full mt-3">
        <input
          type="text"
          onChange={e => onSearchChange(e.target.value)}
          value={search}
          autoFocus
          placeholder={t('channel-search-placeholder')}
          className="bg-transparent w-full border-b border-grey-9 outline-none text-grey-7 font-notosans text-lg leading-8 ps-4 pb-4"
        />
      </div>
      {channels?.map((channel, index) => {
        const hasChildren = Boolean(channel.children?.length);
        const isOpen = clickedChannel?.languageCode === channel.languageCode;
        return (
          <Tooltip.Provider key={channel.languageCode} delayDuration={0}>
            <Tooltip.Root
              open={isOpen}
              onOpenChange={() => setClickedChannel(channel)}
            >
              <Tooltip.Trigger asChild>
                <button
                  onClick={
                    hasChildren
                      ? () => setClickedChannel(channel)
                      : () => onChannelSet(channel)
                  }
                  key={channel.languageCode}
                  className={cn(
                    'bg-white px-6 flex items-center justify-center h-[58px] rounded-custom  text-xl font-sf uppercase font-bold text-[#4F5657] hover:text-white',
                    TRT_CHANNEL_LOGO_MULTICOLOR_CLASSNAME,
                  )}
                >
                  {/* Channel logo, we will show default logo on the initial, and on hover we will show the white one */}
                  <ChannelLogoImage
                    languageName={channel.name}
                    languageCode={channel.languageCode}
                    src={
                      (channel.websiteLogo as unknown as SelectedMedia['media'])
                        ?.url
                    }
                  />
                </button>
              </Tooltip.Trigger>

              {channel.children?.length ? (
                <Tooltip.Content
                  sideOffset={5}
                  style={{ zIndex: 99, margin: 20, marginTop: -40 }}
                  side="bottom"
                >
                  <div
                    className={clsx(
                      'flex flex-col m-auto bg-grey-13 rounded-custom w-[320px] h-auto px-4 py-4 justify-center items-center text-xl font-sf uppercase font-bold text-white',
                    )}
                  >
                    <ChannelLogoImage
                      languageName={channel.name}
                      languageCode={channel.languageCode}
                      className="mb-2 px-2"
                      src={
                        (
                          channel.websiteLogo as unknown as SelectedMedia['media']
                        )?.url
                      }
                      logoVariant={LogoVariant.Light}
                    />
                    <div className="h-[1px] w-full bg-grey-8 mb-2" />

                    <div className="flex flex-row">
                      {channel.children.map(mChannel => (
                        <button
                          onClick={() => onChannelSet(mChannel)}
                          key={`${mChannel.languageCode}-${index}`}
                          className="px-2 py-1 font-sf text-sm leading-4 font-medium uppercase text-grey-1 hover:text-grey-8"
                        >
                          {mChannel.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </Tooltip.Content>
              ) : null}
            </Tooltip.Root>
          </Tooltip.Provider>
        );
      })}
      {channels?.length === 0 && (
        <div className="w-full text-grey-12 font-notosans text-xl col-span-full">
          No results found. Please try searching with a different channel name.
        </div>
      )}
    </div>
  );
}
