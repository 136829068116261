import {
  CHANNEL_LOCALE_COOKIE_KEY,
  CHANNEL_NAME_COOKIE_KEY,
} from '@/i18n/config';
import { Channel } from '@/lib/model';
import { generateChannelPath } from '@/utils';
import { useCookies } from 'react-cookie';

export function useSetChannel() {
  const [_, setLocaleCookie] = useCookies([CHANNEL_LOCALE_COOKIE_KEY]);
  const [__, setNameCookie] = useCookies([CHANNEL_NAME_COOKIE_KEY]);

  return (channel: Channel) => {
    setLocaleCookie(CHANNEL_LOCALE_COOKIE_KEY, channel.languageCode, {
      path: '/',
    });

    setNameCookie(CHANNEL_NAME_COOKIE_KEY, generateChannelPath(channel), {
      path: '/',
    });
    if (
      !window.location.pathname.startsWith(`/${generateChannelPath(channel)}`)
    ) {
      // const queries = new URLSearchParams(window.location.search);
      // const queryString = queries.toString();
      window.location.href = `/${generateChannelPath(channel)}`;
    }
  };
}
