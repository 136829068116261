import { cn } from '@/lib/utils';
import { HTMLAttributes } from 'react';
import { TRT_CHANNEL_LOGO_CLASSNAME } from '../../header/constant';
import { LogoVariant } from '@/utils/setChannelColor';
import styles from '../channelStyle.module.scss';

const SHORT_CHANNEL_NAME_LENGTH = 7;
const isLargeChannelName = (name: string) => {
  return name.length > SHORT_CHANNEL_NAME_LENGTH;
};
interface Props extends HTMLAttributes<HTMLDivElement> {
  src?: string | null;
  languageName: string;
  languageCode: string;
  logoVariant?: LogoVariant;
  withOutTRT?: boolean;
}

export const ChannelLogoImage = ({
  languageCode,
  languageName,
  className,
  logoVariant = LogoVariant.Dark,
  withOutTRT,
}: Props) => {
  const channelName = languageName?.split(' ')?.[0];

  return (
    <img
      data-name={languageCode}
      src={`/svg/channels/${languageCode}${withOutTRT ? '-name-only' : ''}.svg`}
      className={cn(
        `w-auto text-lg flex justify-center align-center`,
        TRT_CHANNEL_LOGO_CLASSNAME,
        {
          ['md:text-sm text-lg h-auto']: isLargeChannelName(channelName),
          ['md:text-lg text-xl h-auto']: !isLargeChannelName(channelName),
          [styles.lightChannelLogoFilterEffect]:
            logoVariant === LogoVariant.Light,
        },
        className,
      )}
    />
  );
};
