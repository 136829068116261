/* eslint no-use-before-define: 0 */

import { Channel } from '@/lib/model';

export enum Locale {
  'fa-AF' = 'fa-AF',
  ar = 'ar',
  az = 'az',
  bg = 'bg',
  bs = 'bs',
  da = 'da',
  de = 'de',
  el = 'el',
  en = 'en',
  es = 'es',
  fa = 'fa',
  fi = 'fi',
  fr = 'fr',
  ha = 'ha',
  he = 'he',
  hi = 'hi',
  hr = 'hr',
  hu = 'hu',
  hy = 'hy',
  id = 'id',
  it = 'it',
  ja = 'ja',
  ka = 'ka',
  kir = 'kir',
  kk = 'kk',
  ko = 'ko',
  mk = 'mk',
  ms = 'ms',
  nl = 'nl',
  no = 'no',
  pcm = 'pcm',
  pl = 'pl',
  ps = 'ps',
  pt = 'pt',
  ro = 'ro',
  ru = 'ru',
  so = 'so',
  sq = 'sq',
  sr = 'sr',
  sv = 'sv',
  sw = 'sw',
  tat = 'tat',
  tuk = 'tuk',
  uig = 'uig',
  ur = 'ur',
  uz = 'uz',
  zh = 'zh',
}

// TODO: The EN channel this is not included in channel query need to be removed after fixing
export const defaultChannel: Channel = {
  id: '6687d8472afdd9db961274e1',
  name: 'world',
  languageCode: 'en',
  updatedAt: '',
  createdAt: '',
};

export const i18n = {
  defaultLocale: 'en',
  locales: (Object.keys(Locale) as Array<keyof typeof Locale>).map(k => k),
};

export type TranslationInstance = Record<string, string>;

export const CHANNEL_LOCALE_COOKIE_KEY = 'trt_channel_locale';
export const CHANNEL_NAME_COOKIE_KEY = 'trt_channel_name';
export const REQUEST_SHARED_CONTEXT_KEY = 'Request-Shared-Context-Key';
export const URL_CHANNEL_KEY = 'channel';
export const URL_LOCALE_KEY = 'locale';
export const MOBILE_THEME_KEY = 'theme';
export const CHANNEL_HEADER = 'Channel-Header';
export const LOCALE_HEADER = 'Locale-Header';
export const HEADER_USER_CHANNEL = 'User-Channel';
export const CLIENT_COOKIE_KEY = 'trt-client-cookie';
export const ETELEVISION_CHANNEL_IDS = {
  ARABI: 6,
  WORLD: 31,
};
