import { TRT_CHANNEL_LOGO_CLASSNAME } from '@/app/components/header/constant';

export enum LogoVariant {
  Light = 'light',
  Dark = 'dark',
  Dynamic = 'dynamic',
}

export const HOVER_TOGGLE_CHANNEL_COLOR_CLASSNAME =
  'filter hover:invert hover:hue-rotate-180 hover:brightness-150';
export function toggleChannelColor(el: Element, invert: boolean) {
  const container = el.querySelector(`.${TRT_CHANNEL_LOGO_CLASSNAME}`);
  if (container?.shadowRoot?.querySelector('svg')?.style) {
    (container.shadowRoot.querySelector('svg') as SVGElement).style.filter =
      invert ? 'invert(100%)' : 'invert(0%)';
  }
}

export function setChannelColor(svg: SVGAElement, variant: LogoVariant) {
  if (!svg) return;

  if (variant) {
    svg.setAttribute('color', 'inherit');
    return;
  }

  svg.setAttribute('color', variant === 'dark' ? '#4F5657' : '#EAEAEA');
}
